var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"86851b8985ad4b78724970d50e9258b6e7fe620c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable import/namespace */
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://38135d487fed2d5de6fa0a8b4353d9af@o4506630241255424.ingest.us.sentry.io/4506630247940096',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate:
    process.env.NEXT_PUBLIC_TRACE_SAMPLING_RATE == null
      ? 0.1
      : Number.parseFloat(process.env.NEXT_PUBLIC_TRACE_SAMPLING_RATE),

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate:
    process.env.NEXT_PUBLIC_ERROR_REPLAY_SAMPLE_RATE == null
      ? 0.1
      : Number.parseFloat(
          process.env.NEXT_PUBLIC_ERROR_REPLAY_SAMPLE_RATE ?? '0',
        ),
  environment: process.env.NEXT_PUBLIC_ENV,
  ignoreErrors: [
    'globalThis is not defined',
    "Cannot read properties of undefined (reading 'site_id')",
    '$ is not defined',
    'CustomError',
    '<html>',
    'gb',
    '[Hypertune]',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_us\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate:
    process.env.NEXT_PUBLIC_REPLAY_SAMPLE_RATE == null
      ? Number.parseFloat(process.env.NEXT_PUBLIC_REPLAY_SAMPLE_RATE ?? '0')
      : 0.1,
  profilesSampleRate:
    process.env.NEXT_PUBLIC_PROFILE_SAMPLING_RATE == null
      ? 0.01
      : Number.parseFloat(process.env.NEXT_PUBLIC_PROFILE_SAMPLING_RATE),

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    ...(process.env.NEXT_PUBLIC_REPLAY_SAMPLE_RATE === '0'
      ? []
      : [
          // eslint-disable-next-line import/namespace
          Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
          }),
        ]),
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
})
